<template>
  <v-row>
    <template v-for="channel in channels">
      <v-col
        :key="channel.uuid"
        cols="4"
      >
        <ChannelCard :channel="channel" />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import ChannelCard from './ChannelCard'

export default {
    name: 'ChannelGrid',
    props: {
      channels: {
        type: Array,
        default: () => []
      }
    },
    components: {
        ChannelCard
    }
}
</script>